import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/register',
        name: 'Admin Reg',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/auth/FirstAdminReg.vue'
            ),
    },
    {
        path: '/admin-login',
        name: 'Login',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/auth/AdminLogin.vue'
            ),
    },
    {
        path: '/login',
        name: 'Agency Login',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/auth/AgencyLogin.vue'
            ),
    },
    {
        path: '/admin-management',
        name: 'Admin Management',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SuperAdmin/AdminManagement.vue'
            ),
    },
    {
        path: '/add-admin',
        name: 'Register Admin',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SuperAdmin/RegisterAdmin.vue'
            ),
    },
    {
        path: '/agency-management',
        name: 'Agency Management',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SuperAdmin/AgencyManagement.vue'
            ),
    },
    {
        path: '/add-agency',
        name: 'Add Agency',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SuperAdmin/AddAgency.vue'
            ),
    },
    {
        path: '/agency/:id',
        name: 'View Agency',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SuperAdmin/ViewAgency.vue'
            ),
    },

    {
        path: '/admin-reg',
        name: 'Admin Registration',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/auth/FirstAdminReg.vue'
            ),
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Dashboard.vue'
            ),
    },

    {
        path: '/register-staff',
        name: 'Register Staff',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/RegisterStaff.vue'
            ),
    },
    {
        path: '/agency-services/1',
        name: 'Agency Services',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/AgencyServices.vue'
            ),
    },
    {
        path: '/agency-settings',
        name: 'Agency Settings',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/AgencySettings.vue'
            ),
    },
    {
        path: '/job-titles',
        name: 'Job Titles',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/JobTitles.vue'
            ),
    },
    {
        path: '/facility-management',
        name: 'Facility Management',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/FacilityManagement.vue'
            ),
    },
    {
        path: '/facility_type',
        name: 'Facility Type',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/FacilityType.vue'
            ),
    },
    {
        path: '/register-facility',
        name: 'RegisterFacility',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/RegisterFacility.vue'
            ),
    },
    {
        path: '/facility/:id',
        name: 'View Facility',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/ViewFacility.vue'
            ),
    },
    {
        path: '/single_facility/:id',
        name: 'Single Facility',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/SingleFacility.vue'
            ),
    },
    {
        path: '/form-management',
        name: 'Form Management',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/FormManagement.vue'
            ),
    },
    {
        path: '/add-form',
        name: 'Add Form',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/AddForm.vue'
            ),
    },
    {
        path: '/view-form/:id',
        name: 'Single Form',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/SingleForm.vue'
            ),
    },
    {
        path: '/all-inspections',
        name: 'AllInspections',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/AllInspections.vue'
            ),
    },

    {
        path: '/inspection/:id',
        name: 'SingleInspection',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/SingleInspection.vue'
            ),
    },

    {
        path: '/inspection-form',
        name: 'Inspection Form',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/InspectionForm.vue'
            ),
    },
    {
        path: '/view-form/question/:id',
        name: 'Form Category',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Agency/FormQuestion.vue'
            ),
    },
    {
        path: '/application',
        name: 'ViewApplication',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/ViewApplication.vue'
            ),
    },
    {
        path: '/license-renewals',
        name: 'License Renewals',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/Renewal.vue'
            ),
    },
    {
        path: '/single-renewal',
        name: 'Single Renewal',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/SingleRenewal.vue'
            ),
    },

    {
        path: '/admin_settings',
        name: 'Admin Settings',
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/pages/AdminSettings.vue'
            ),
    },

    {
        path: '*',
        name: '404',
        redirect: { name: 'Agency Login' },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
