<template>
<<<<<<< HEAD
  <div>
    <router-view />

    <BottomNav v-if="user != null" />
  </div>
</template>

<script>
import BottomNav from "@/views/BottomNav.vue";

export default {
  components: {
    BottomNav,
  },
  data() {
    return {
      sideNavOpen: false,
      user: null,
    };
  },
  methods: {},
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>
=======
    <div>
        <router-view />
    </div>
</template>

<style></style>
>>>>>>> bc582aba038c98112cadea2837943235ccd6a2e2
