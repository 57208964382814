<template>
  <ul class="bottom-nav">
    <li>
      <div class="flex justify-center space-y-2">
        <i class="las la-home"></i>
      </div>
      <router-link to="/dashboard">
        <span>Home</span>
      </router-link>
    </li>
    <li>
      <div class="flex justify-center">
        <i class="las la-hospital"></i>
      </div>
      <router-link to="/facility-management">
        <span>Facilities</span>
      </router-link>
    </li>
    <li>
      <div class="flex justify-center">
        <i class="las la-notes-medical"></i>
      </div>

      <router-link to="all-inspections">
        <span>M&E</span>
      </router-link>
    </li>

    <li>
      <div class="flex justify-center">
        <i class="las la-comment"></i>
      </div>

      <router-link to="sop-management">
        <span>SOPs</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: "Home",
        },
      ],
    };
  },
};
</script>


<style scoped>
.bottom-nav {
  @apply flex flex-row fixed  inset-x-0 bottom-0 bg-white w-full  lg:hidden text-black font-bold justify-around;
}
li {
  @apply flex flex-col px-2 py-2;
}

ul a.router-link-exact-active {
  @apply text-primary border-b-2 border-primary;
}
</style>
