import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import 'flowbite';

import axios from 'axios';
import VueAxios from 'vue-axios';
import Toasted from 'vue-toasted';

Vue.config.productionTip = false;

window.axios = axios;
axios.defaults.baseURL = 'https://api.hefrias.ng';
// axios.defaults.baseURL = 'https://api.mybrokerstation.com';
// axios.defaults.baseURL = 'http://localhost:8000';
// axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] =
    'Bearer ' + localStorage.getItem('jwt');

new Vue({
    router,
    store,
    axios,
    VueAxios,
    Toasted,
    render: (h) => h(App),
}).$mount('#app');

Vue.use(Toasted);
